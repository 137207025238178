<script setup lang="ts">
  function toUp() {
    window.scrollTo(0, 0);
  }
</script>
<template>
  <div class="container">
    <div class="content">
      <p class="subtitle">Информация для ознакомления, желающим отправить обращение в форме электронного документа</p>
      <div class="list-instr">
        <p>
          Пожалуйста, прежде чем отправить обращение в форме электронного документа, внимательно ознакомьтесь с полномочиями и сферой деятельности Автономная Некоммерческая
          Организация "Центр Цифровой Трансформации Республики Тыва", а также со следующей информацией.
        </p>
        <p>
          1. Обращения, направленные в форме электронного документа через официальный сайт, поступают на рассмотрение в Автономная Некоммерческая Организация "Центр Цифровой
          Трансформации Республики Тыва" и рассматриваются работниками Автономная Некоммерческая Организация "Центр Цифровой Трансформации Республики Тыва" — уполномоченными
          на то лицами.
        </p>
        <p>2. Перед отправкой обращения в форме электронного документа необходимо его написать.</p>
        <p>2.1. в обязательном порядке указав в электронной анкете:</p>
        <p>
          2.1.1. либо наименование государственного органа, в которой вы направляете обращение в форме электронного документа, либо фамилию, имя, отчество соответствующего лица,
          либо должность соответствующего лица, кому вы направляете обращение в форме электронного документа;
        </p>
        <p>2.1.2. свою фамилию, имя, отчество (последнее — при наличии);</p>
        <p>2.1.3. адрес электронной почты, по которому должны быть направлены ответ, уведомление о переадресации обращения;</p>
        <p>2.2. изложив в поле ввода текста обращения в форме электронного документа суть предложения, заявления, жалобы.</p>
        <p>
          3. Ответ на ваше обращение в форме электронного документа либо уведомление о его переадресации направляется в форме электронного документа по адресу электронной почты
          (е-mail), указанному вами в обращении в форме электронного документа.
        </p>
        <p>
          4. В предназначенном для обязательного заполнения поле ввода текста обращения в форме электронного документа вы излагаете суть предложения, заявления или жалобы
          в соответствии со ст. 7 Федерального закона от 2 мая 2006 года № 59-ФЗ «О порядке рассмотрения обращений граждан Российской Федерации»
        </p>
        <p>
          В случае, если текст вашего обращения не позволяет определить суть предложения, заявления или жалобы, ответ на обращение не даётся, и оно не подлежит направлению
          на рассмотрение в государственный орган, орган местного самоуправления или должностному лицу в соответствии с их компетенцией, о чем вам будет сообщено в течение семи
          дней со дня регистрации обращения.
        </p>
        <p>Текст в электронной форме, набранный и отправленный через информационный ресурс «Личный кабинет», сохраняется и отображается в «Личном кабинете» автора.</p>
        <p>
          Обращаем внимание, что в целях обеспечения неразглашения сведений, содержащихся в Вашем обращении, а также сведений, касающихся вашей частной жизни, при заполнении поля
          ввода текста обращения в форме электронного документа действует защита от возможного внедрения вредоносного кода.
        </p>
        <p>Запрет на копирование и перенос текста в электронной форме в поле ввода текста обращения в форме электронного документа.</p>
        <p>5. В случае необходимости в подтверждение своих доводов вы вправе приложить к обращению необходимые документы и материалы в электронной форме.</p>
        <p>Приложить необходимые документы и материалы в электронной форме вы можете в любой последовательности (указываются количество и допустимые форматы файлов).</p>
        <p>Иные форматы не обрабатываются в информационных системах Автономная Некоммерческая Организация "Центр Цифровой Трансформации Республики Тыва"</p>
        <p>
          Информируем вас, что передача файла(ов) вложения на почтовый сервер зависит от пропускной способности сети «Интернет», а получение — от объёма обрабатываемых почтовым
          сервером переданных файлов.
        </p>
        <p>
          При подключении вашего оборудования к сети «Интернет» по выделенным каналам связи с использованием технологий ADSL, 3G, 4G, Wi-Fi и иных технологий, обеспечивающих
          аналогичные скорости передачи данных в сети «Интернет», передача и обработка файла(ов) с суммарным размеров:
        </p>
        <p>до 5 Мб осуществляется, как правило, без задержки во времени;</p>
        <p>от 5 Мб до 10 Мб может осуществляться с задержкой во времени;</p>
        <p>свыше 10 Мб может быть не осуществлена.</p>
        <p>
          6. Если в направленном вами тексте в форме электронного документа, содержащемся в поле ввода текста обращения в форме электронного документа, не изложено предложение,
          заявление или жалоба, а только ссылка на приложение (файл вложение) либо контент интернет-сайта, то в ответе разъясняется порядок его рассмотрения, установленный
          Федеральным законом от 2 мая 2006 года № 59-ФЗ «О порядке рассмотрения обращений граждан Российской Федерации
        </p>
        <p>
          7. Обращаем ваше внимание на порядок рассмотрения отдельных обращений, предусмотренный ст. 11 Федерального закона от 2 мая 2006 года № 59-ФЗ „О порядке рассмотрения
          обращений граждан Российской Федерации“.
        </p>
        <p>8.При направлении вами обращений, касающихся обжалования судебных решений, необходимо иметь в виду следующее:</p>
        <p>
          Согласно Конституции Российской Федерации правосудие в России осуществляется только судом. Органы судебной власти самостоятельны и действуют независимо от законодательной
          и исполнительной властей. Решения судебных органов обжалуются в установленном законом процессуальном порядке.
        </p>
        <p>
          9. В случае направления вами обращения, содержащего вопрос, затрагивающий интересы неопределённого круга лиц, ответ на который был размещён на данном сайте,
          то вам в течение семи дней со дня регистрации обращения будет сообщён электронный адрес страницы данного сайта „Ответы на обращения, затрагивающие интересы
          неопределённого круга лиц“, на которой размещён ответ на вопрос, поставленный в Вашем обращении
        </p>
        <p>
          10. Информация о персональных данных авторов обращений, направленных в форме электронного документа, сведения, содержащиеся в обращениях авторов, а также сведения,
          касающиеся частной жизни авторов, хранятся и обрабатываются с соблюдением требований российского законодательства.
        </p>
        <p>
          11. В „Личном кабинете“ после авторизации вам предоставляется возможность получения хронологически структурированной информации о ходе и результатах рассмотрения
          отправленных вами через „Личный кабинет“ обращений, адресованных должностному лицу и органу, и запросов, касающихся деятельности должностного лица и органа.
        </p>
        <p>
          В „Личном кабинете“ размещаются данные по каждому отправленному вами с „Личного кабинета“ обращению или запросу с момента регистрации автора на информационном ресурсе
          „Личный кабинет“ в разделе (указывается наименование раздела) на официальном сайте органа.
        </p>
        <p>
          12. Предусмотрена возможность просмотра сайта на различных устройствах — от смартфонов и планшетов до широкоформатных мониторов. А при ширине окна браузера выше
          1570 пикселей вы можете читать материалы сайта, сохраняя при этом доступ к навигации по странице, которой вы перешли на выбранную публикацию.
        </p>
        <p>Адрес для отправки обращений в письменной форме в Автономная Некоммерческая Организация "Центр Цифровой Трансформации Республики Тыва" обычной почтой: info@anocct.ru</p>
      </div>
      <div class="button-sendmail"><router-link :to="{ name: 'sendmail' }" @click="toUp()"> НАПИСАТЬ ПИСЬМО </router-link></div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .list-instr {
    & p {
      font-size: 1rem;
    }
  }
  .button-sendmail {
    display: flex;
    justify-content: center;
    & a {
      text-decoration: none;
      color: var(--text-color);
      background-color: var(--primary-color);
      padding: 1rem;
      border-radius: 1rem;
    }
  }
  @media (max-width: 768px) {
    .list-instr {
      & p {
        font-size: 1rem;
      }
    }
    .button-sendmail {
      & a {
        font-size: 1rem;
      }
    }
  }
</style>
