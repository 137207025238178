import { ref } from "vue";
import iStore from "@/types/iStore";
export const vacancyState: iStore.VacancyState = {
  state: ref([
    {
      name: "Начальник отдела образования",
      salary: "от 50 000 ₽", //зарплата
      experience: "Без опыта", //опыт
      employment: "Полная занятость, полный день", //занятость
      duties: [
        "1. Организация и управление проектом по разработке и внедрению государственной информационной системы в регионе.",
        "2. Координация работы с внешними исполнителями и партнерами.",
        "3. Управление командой специалистов, в том числе распределение задач, контроль выполнения и оценка результатов.",
        "4. Разработка стратегии и тактики продвижения проекта в образовательной среде.",
        "5. Участие в переговорах и консультациях с органами власти, образовательными учреждениями и другими заинтересованными структурами.",
      ], //обязаности
      requirements: [
        "1. Высшее образование, предпочтительно в области информационных технологий или образования.",
        "2. Опыт работы в управлении проектами, желательно в образовательной сфере.",
        "3. Навыки командного руководства и управления персоналом.",
        "4. Опыт ведения переговоров и работы с заказчиками и партнерами.",
        "5. Знание принципов построения ГИС и их применение в образовательной сфере.",
        "6. Желание развиваться и повышать свои профессиональные навыки.",
      ], //требования
      conditions: [
        "1. Официальное трудоустройство.",
        "2. График работы с 9:00 до 18:00, обеденный перерыв с 13:00 до 14:00.",
        "3. Конкурентная заработная плата и премии по результатам работы.",
        "4. Возможности для профессионального и карьерного роста.",
        "5. Корпоративное обучение и развитие.",
        "6. Дружеская и профессиональная атмосфера в коллективе.",
      ], //условия
    },
  ]),
};
