import { ref } from "vue";
import iStore from "@/types/iStore";
export const newsState: iStore.NewsState = {
  state: ref([
    {
      id: 0,
      title: "Первая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 1,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 2,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 3,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 4,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 5,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 6,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 7,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 8,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 9,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 10,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 11,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 12,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 13,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 14,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 15,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 16,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 17,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
    {
      id: 18,
      title: "Вторая новость",
      description:
        "Кстати, акционеры крупнейших компаний и по сей день остаются уделом либералов, которые жаждут быть подвергнуты целой серии независимых исследований. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предопределяет высокую востребованность анализа существующих паттернов поведения...",
      date: "16.04.2024 10:28",
    },
  ]),
};
